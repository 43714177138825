import React, { useEffect, useState } from 'react';

const Dobra6 = () => {
    const [visible, setVisible] = useState(false);
    const [countdown, setCountdown] = useState('');
    
    // Defina sua data de término aqui
    const endDate = new Date('2024-11-30T23:59:59'); // Exemplo: 30 de novembro de 2024, 23:59:59

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const updateCountdown = () => {
        const now = new Date();
        const timeRemaining = endDate - now;

        if (timeRemaining <= 0) {
            setCountdown('Oferta Expirada!');
        } else {
            const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
            setCountdown(`${days} dias, ${hours} horas, ${minutes} minutos, ${seconds} segundos`);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        
        // Atualiza o timer a cada segundo
        const timer = setInterval(updateCountdown, 1000);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(timer);
        };
    }, []);

    return (
        <a href="https://wa.me/5511961206560?text=Ol%C3%A1%2C%20gostaria%20de%20um%20or%C3%A7amento%21">
             <div className={`dobra6 ${visible ? 'visible' : ''}`}>
            <p className='limits'>
                Oferta por Tempo Limitado! - 🎉 <strong>Solicite sua cotação até o final do mês e ganhe 10% de desconto!</strong> 🎉 -
                ⏳ Restam apenas <span id="countdown">{countdown}</span> para aproveitar esta oferta!
            </p>
        </div>
        </a>
    );
};

export default Dobra6;
