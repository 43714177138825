import React from 'react';

const Dobra1 = () => {
    return (
        <section className="hero-section ff">
            <div className="hero-content">
                <h1>Bem Casados Lilica Patisserie — Celebre o Amor com o Doce da União Perfeita</h1>
                <p>Um doce tão especial quanto o seu dia. Experimente a delicadeza e o sabor incomparável do Bem Casado, feito à mão para tornar seu momento inesquecível.</p>
                <a className="cta-button" href="https://wa.me/5511961206560?text=Ol%C3%A1%2C%20gostaria%20de%20um%20or%C3%A7amento%21">Solicite sua Cotação Personalizada</a>
            </div>
            <div className="hero-image">
                <img src="images/WhatsApp Image 2024-09-16 at 20.15.03.jpeg" alt="Bem Casados delicadamente embalados com recheio cremoso de doce de leite" />
            </div>
        </section>
    );
};

export default Dobra1;
