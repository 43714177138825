import React from 'react'
import Dobra1 from './Dobras/Dobra1'
import Dobra2 from './Dobras/Dobra2'
import Carousel from './Dobras/Dobra3'
import Dobra4 from './Dobras/Dobra4'
import Dobra6 from './Dobras/Dobra6'
import Dobra7 from './Dobras/Dobra7'

function lp1() {
  return (
    <div>
       <a href="https://wa.me/5511961206560?text=Ol%C3%A1%2C%20gostaria%20de%20um%20or%C3%A7amento%21" className="whatsapp-button">
            <img className='imswe' src="images/whatsapp.png" alt="whatsapp" />
</a>
     <Dobra1 />
     <Dobra2 />
     <Carousel />
     <Dobra4 />
     <Dobra7 />
     <Dobra6 />
    </div>
  )
}

export default lp1